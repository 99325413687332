import { useState } from "react";
import loadRecruitics from "~/utils/tracking/load_recruitics";

const useRecruitics = (enableRecruitics: boolean) => {
  const [recruitics, setRecruitics] = useState(false);

  if (!recruitics) {
    void loadRecruitics(enableRecruitics);
    setRecruitics(true);
  }
};

export default useRecruitics;
